<template>
  <div v-if="isshowgrid">
    <va-card title="Channel List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex md2 xs12 md2 offset--md2">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Channel Name</span>
            <va-input
              v-model.trim="channel_name"
              type="text"
              placeholder="Enter Channel Name"
              :error="!!channelNameErrors.length"
              :error-messages="channelNameErrors"
            />
            <span class="va-form-label va-form-required-label">LCN Number</span>
            <va-input
              v-model.trim="lcn_number"
              type="text"
              placeholder="Enter LCN Number"
              :error="!!lcnNumberErrors.length"
              :error-messages="lcnNumberErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'')"
            />
            <span class="va-form-label va-form-required-label">Channel Cost</span>
            <va-input
              v-model.trim="channel_cost"
              type="text"
              placeholder="Enter Channel Cost"
              :error="!!channelCostErrors.length"
              :error-messages="channelCostErrors"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'')"
            />
            <div>
              <span class="va-form-label va-form-required-label">Language</span>
              <va-select
                placeholder="Enter Language"
                v-model="language"
                searchable
                textBy="language"
                :options="languageArr"
                :error="!!languageErrors.length"
                :error-messages="languageErrors"
              />
            </div>
            <div>
              <span class="va-form-label va-form-required-label">Genres</span>
              <va-select
                placeholder="Enter Genres"
                v-model="genres"
                searchable
                textBy="genres"
                :options="genresArr"
                :error="!!genresErrors.length"
                :error-messages="genresErrors"
              />
            </div>
            <div>
              <span class="va-form-label va-form-required-label">Type</span>
              <va-select
                placeholder="Select Type"
                v-model="channel_type"
                searchable
                textBy="channel_type"
                :options="typeArr"
                :error="!!typeErrors.length"
                :error-messages="typeErrors"
              />
            </div>
            <div>
              <span class="va-form-label va-form-required-label">Quality</span>
              <va-select
                placeholder="Select Quality"
                v-model="quality"
                searchable
                textBy="quality"
                :options="qualityArr"
                :error="!!qualityErrors.length"
                :error-messages="qualityErrors"
              />
            </div>
            <div class="flex xs12">
              <label class="va-form-label va-form-required-label">Description</label>
              <textarea class="text_box mr-5" style="width: 900px;" v-model="description" :error="!!descriptionErrors.length"
                :error-messages="descriptionErrors" />
              <!-- <textarea class="text_box" v-model="description" :disabled ="true" /> -->
            </div>
            <span v-if="isCreate" class="va-form-label va-form-required-label">Channel Logo</span>
            <span v-if="isUpdate" class="va-form-label">Channel Logo</span>
            <va-file-upload
              type="single"
              dropzone
              @input="CheckFile('logo')"
              v-model="channel_logo"
              color="#D3D3D3"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createChannel()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateChannel()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'channel',
  created () {
    this.getAllChannel()
    var vm = this
    this.$http.get(config.menu.host + '/genres').then(response => {
      vm.genresArr = response.body
      this.genresArr.map(function (arg, i) { arg.id = i + 1 })
    })
    this.$http.get(config.menu.host + '/language').then(resp => {
      vm.languageArr = resp.body
    })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      channel_name: '',
      lcn_number: '',
      channel_cost: '',
      channel_type: '',
      quality: '',
      genres: '',
      language: '',
      logo_name: '',
      channel_logo: [],
      channelData: [],
      typeArr: [{ id: 1, channel_type: 'FTA' }, { id: 2, channel_type: 'PAID' }],
      qualityArr: [{ id: 1, quality: 'HD' }, { id: 2, quality: 'SD' }],
      genresArr: [],
      languageArr: [],
      channelNameErrors: [],
      lcnNumberErrors: [],
      channelCostErrors: [],
      typeErrors: [],
      qualityErrors: [],
      descriptionErrors: [],
      genresErrors: [],
      languageErrors: [],
      order: '',
      title: '',
      sc_category: '',
      genresArr: [],
      description: '',
    }
  },
  computed: {
    Check_upload () {
      if (this.channel_logo[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload images', type: 'error' })
        return false
      }
    },
    formReady () {
      return !this.channelNameErrors.length && !this.lcnNumberErrors.length && !this.channelCostErrors.length && !this.typeErrors.length && !this.qualityErrors.length && !this.genresErrors.length && !this.languageErrors.length && !this.descriptionErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      },
      {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'channel_name',
        title: 'Channel Name',
      },
      {
        name: 'lcn_number',
        title: 'LCN Number',
      },
      {
        name: 'channel_type',
        title: 'Channel Type',
      },
      {
        name: 'quality',
        title: 'Quality',
      },
      {
        name: 'channel_cost',
        title: 'Channel Cost',
      },
      {
        name: 'genres',
        title: 'Genres',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.channelData)
    },
  },
  methods: {
	    getAllChannel () {
	      this.$http.get(config.menu.host + '/channel_meta').then(response => {
        let index = 0
        this.channelData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
	        this.isshowgrid = true
	      })
	    },
    CheckFile (type) {
      if (typeof (type) !== 'undefined') {
        var image_file
        image_file = this.channel_logo[0]
        if ((type == 'logo') && (image_file !== undefined)) {
          var file_name = image_file.name.split('.').pop()
          if ((file_name.toLowerCase() === 'png') || (file_name.toLowerCase() === 'jpg') || (file_name.toLowerCase() === 'jpeg')) {
            const img = new Image()
            const reader = new FileReader()
            reader.readAsDataURL(image_file)
            reader.onload = evt => {
              img.onload = () => {
                this.logo_name = image_file.name
              }
              img.src = evt.target.result
            }
          } else {
            this.channel_logo = ''
            Vue.notify({ text: 'Please check the image Format', type: 'error' })
          }
        }
      }
    },
    createChannel () {
      this.channelNameErrors = this.channel_name ? [] : ['Channel Name is required']
      this.lcnNumberErrors = this.lcn_number ? [] : ['LCN Number is required']
      this.channelCostErrors = this.channel_cost ? [] : ['Channel Cost is required']
      this.typeErrors = this.channel_type ? [] : ['Channel Type is required']
      this.qualityErrors = this.quality ? [] : ['Quality is required']
      this.genresErrors = this.genres ? [] : ['Genres is required']
      this.languageErrors = this.language ? [] : ['Language is required']
      this.descriptionErrors = this.description ? [] : ['Description is required']

      if (!this.formReady) {
        return
      }
      if (!this.Check_upload) {
        Vue.notify({ text: 'Please upload an image' })
        return
      }
      var payload = new FormData()
      payload.append('logo', this.channel_logo[0])
      payload.append('channel_name', this.channel_name)
      payload.append('lcn_number', this.lcn_number)
      payload.append('channel_cost', this.channel_cost)
      payload.append('logo_name', this.logo_name)
	      payload.append('channel_type', this.channel_type.channel_type)
      payload.append('quality', this.quality.quality)
      payload.append('language', this.language.language)
      payload.append('genres', this.genres.genres)
      payload.append('description', this.description)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/channel_meta', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateChannel () {
      this.channelNameErrors = this.channel_name ? [] : ['Channel Name is required']
      this.lcnNumberErrors = this.lcn_number ? [] : ['LCN Number is required']
      this.channelCostErrors = this.channel_cost ? [] : ['Channel Cost is required']
      this.typeErrors = this.channel_type ? [] : ['Channel Type is required']
      this.qualityErrors = this.quality ? [] : ['Quality is required']
      this.genresErrors = this.genres ? [] : ['Genres is required']
      this.languageErrors = this.language ? [] : ['Language is required']
      this.descriptionErrors = this.description ? [] : ['Description is required']
      if (!this.formReady) {
        return
      }
      var vm = this
      if (typeof (this.channel_type) === 'object') {
        vm.channel_type = this.channel_type.channel_type
      }
      if (typeof (this.quality) === 'object') {
        vm.quality = this.quality.quality
      }
      if (typeof (this.language) === 'object') {
        vm.language = this.language.language
      }
      if (typeof (this.genres) === 'object') {
        vm.genres = this.genres.genres
      }
      var payload = new FormData()
      if (this.channel_logo[0]) {
        payload.append('logo', this.channel_logo[0])
      }
      payload.append('channel_name', this.channel_name)
      payload.append('lcn_number', this.lcn_number)
      payload.append('channel_cost', this.channel_cost)
      payload.append('logo_name', this.logo_name)
      payload.append('channel_type', this.channel_type)
      payload.append('quality', this.quality)
      payload.append('language', this.language)
      payload.append('genres', this.genres)
      payload.append('description', this.description)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/channel_meta/' + this.channel_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
	  add () {
	    this.title = 'Create Channel'
      this.genres = ''
      this.sc_category = ''
      this.description = ''
      this.channel_logo = []
      this.channelNameErrors = []
      this.lcnNumberErrors = []
      this.channelCostErrors = []
      this.typeErrors = []
      this.qualityErrors = []
      this.descriptionErrors = []
      this.genresErrors = []
      this.languageErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
	    },
    edit (row) {
      this.title = 'Update Channel'
      this.channel_id = row.channel_id
      this.genres = row.genres
      this.sc_category = row.sc_category
      this.channel_name = row.channel_name
      this.lcn_number = row.lcn_number
      this.channel_cost = row.channel_cost
      this.channel_type = row.channel_type
      this.quality = row.quality
      this.genres = row.genres
      this.language = row.language
      this.description = row.description
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.channel_logo = []
      this.getAllChannel()
      this.genresErrors = []
      this.channel_name = ''
      this.lcn_number = ''
      this.channel_cost = ''
      this.language = ''
      this.genres = ''
      this.channel_type = ''
      this.quality = ''
      // this.description = ''
    },
	    search: debounce(function (term) {
	    	this.term = term
	    }, 400),
  },
}
</script>

<style>
.text_box {
  width: 937px;
  height: 101px;
  border-color: black;
  border-radius: 10px;
  resize: none;
}
</style>
